/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export const KanbanWrapper: React.FC = ({ children }) => {
  return (
    <div className="container-kanban">
      <div className="kanban-board">
        {children}
      </div>
    </div>
  )
}

interface KanbanColProps { title: string, empty?: React.ReactNode, counter?: number, actions?: React.ReactNode }
export const KanbanCol: React.FC<KanbanColProps> = ({ title, children, empty, counter, actions }) => {
  return (
    <div className="kanban-col px-0">
      <div className="card-list card-list-flush">
        <div className="card-list-title row align-items-center mb-3">
          <div className="col">
            <h6 className="mb-0">{title}</h6>
          </div>
          <div className="col text-right">
            {!actions && (
              <p className="mb-0">{counter}</p>
            )}
            {actions && (
              <div className="actions">
                <a className="action-item mr-2" href="#modal-task-create" data-toggle="modal"><i className="fas fa-plus"></i></a>
                <div className="dropdown" data-toggle="dropdown">
                  <a href="#" className="action-item" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-ellipsis-h"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <a href="#" className="dropdown-item">Refresh</a>
                    <a href="#" className="dropdown-item">Manage Widgets</a>
                    <a href="#" className="dropdown-item">Settings</a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="card-list-body" id="kanban-blacklist">
          {children}
          <span className="empty-container" data-placeholder="Empty">
            {empty}
          </span>
        </div>
      </div>
    </div>
  )
}
