/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { reverse, sortBy, size, get } from 'lodash'
import { LiveSupport } from '@irontrainers/live-assist/lib/rtdb'
import { useThrottleFn } from '@umijs/hooks'
import moment from 'moment'

import { liveSupport, mainCorporationId } from '../../clients/iron-fit'
import { LiveUserKanbanCardProps } from './components/LiveUserKanbanCard'

export interface LiveSupportKanbanState {
  patientsAwaitingOperator: LiveUserKanbanCardProps[]
  operators: LiveUserKanbanCardProps[]
  patientsAwaitingDoctor: LiveUserKanbanCardProps[]
  doctors: LiveUserKanbanCardProps[]
  patientsAwaitingAssistant: LiveUserKanbanCardProps[]
  assistants: LiveUserKanbanCardProps[]
  activeUsers: number
}

const quitStartTime = moment().subtract(5, 'minutes').valueOf()

export function useLiveSupportKanbanState(): [LiveSupportKanbanState, boolean] {
  const [patientsAwaitingOperator, patientsAwaitingOperatorLoading] = liveSupport.useLivePatientsAwaiting('operator', 'video')
  const [patientsAwaitingDoctor, patientsAwaitingDoctorLoading] = liveSupport.useLivePatientsAwaiting('doctor', 'video')
  const [patientsAwaitingAssistant, patientsAwaitingAssistantLoading] = liveSupport.useLivePatientsAwaiting('assistant', 'video')
  const [operatorsAwaitingPatient, operatorsAwaitingPatientLoading] = liveSupport.useLiveOperatorsAwaitingPatient('video')
  const [operatorsInSupport, operatorsInSupportLoading] = liveSupport.useLiveOperatorsInSupport(true)
  const [operatorsQuittedRecently, operatorsQuittedRecentlyLoading] = liveSupport.useLiveOperatorsQuittedSince(quitStartTime)
  const [doctorsAwaitingPatient, doctorsAwaitingPatientLoading] = liveSupport.useLiveDoctorsAwaitingPatient('video')
  const [doctorsInSupport, doctorsInSupportLoading] = liveSupport.useLiveDoctorsInSupport(true)
  const [doctorsQuittedRecently, doctorsQuittedRecentlyLoading] = liveSupport.useLiveDoctorsQuittedSince(quitStartTime)
  const [assistantsAwaitingPatient, assistantsAwaitingPatientLoading] = liveSupport.useLiveAssistantsAwaitingPatient('video')
  const [assistantsInSupport, assistantsInSupportLoading] = liveSupport.useLiveAssistantsInSupport(true)
  const [assistantsQuittedRecently, assistantsQuittedRecentlyLoading] = liveSupport.useLiveAssistantsQuittedSince(quitStartTime)

  const [liveSupportsInProgress, liveSupportsInProgressLoading] = liveSupport.useLiveSupports({ status: 'IN_PROGRESS' })
  const [liveSupportsPendingStaffReview, liveSupportsPendingStaffReviewLoading] = liveSupport.useLiveSupports({ pendingStaffReview: true })

  const [kanbanState, setKanbanState] = React.useState<LiveSupportKanbanState>({
    patientsAwaitingOperator: [],
    operators: [],
    patientsAwaitingDoctor: [],
    doctors: [],
    patientsAwaitingAssistant: [],
    assistants: [],
    activeUsers: 0,
  })
  function updateKanbanState() {
    const activeUsers: { [userId: string]: boolean } = {}

    const nextPatientsAwaitingOperator: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextOperators: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPatientsAwaitingDoctor: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextDoctors: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextPatientsAwaitingAssistant: { [userId: string]: LiveUserKanbanCardProps } = {}
    const nextAssistants: { [userId: string]: LiveUserKanbanCardProps } = {}

    // ======== operators list
    for (const operatorId in operatorsAwaitingPatient) {
      const operator = operatorsAwaitingPatient[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        awaitingSince: operator.awaitingPatient ? operator.awaitingPatient.video : undefined,
      }
      nextOperators[operatorId] = operatorCard
    }
    for (const operatorId in operatorsInSupport) {
      const operator = operatorsInSupport[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      let support: Partial<LiveSupport> =
        operator.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[operator.runningSupportId] || {} : {}
      if (operator.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[operator.runningSupportId] || {} : {}
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: operator.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextOperators[operatorId] = operatorCard
    }
    for (const operatorId in operatorsQuittedRecently) {
      const operator = operatorsQuittedRecently[operatorId]
      if (mainCorporationId && !get(operator.corporationIds, mainCorporationId, false)) continue
      activeUsers[operatorId] = true
      const operatorCard: LiveUserKanbanCardProps = {
        userId: operatorId,
        userType: 'operator',
        onlineDevices: size(operator.onlineDevices),
        quitSince: operator.quitTime,
      }
      nextOperators[operatorId] = operatorCard
    }

    // ======== doctors list
    for (const doctorId in doctorsAwaitingPatient) {
      const doctor = doctorsAwaitingPatient[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        awaitingSince: doctor.awaitingPatient ? doctor.awaitingPatient.video : undefined,
      }
      nextDoctors[doctorId] = doctorCard
    }
    for (const doctorId in doctorsInSupport) {
      const doctor = doctorsInSupport[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      let support: Partial<LiveSupport> =
        doctor.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[doctor.runningSupportId] || {} : {}
      if (doctor.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[doctor.runningSupportId] || {} : {}
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: doctor.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextDoctors[doctorId] = doctorCard
    }
    for (const doctorId in doctorsQuittedRecently) {
      const doctor = doctorsQuittedRecently[doctorId]
      if (mainCorporationId && !get(doctor.corporationIds, mainCorporationId, false)) continue
      activeUsers[doctorId] = true
      const doctorCard: LiveUserKanbanCardProps = {
        userId: doctorId,
        userType: 'doctor',
        onlineDevices: size(doctor.onlineDevices),
        quitSince: doctor.quitTime,
      }
      nextDoctors[doctorId] = doctorCard
    }

    // ======== assistants list
    for (const assistantId in assistantsAwaitingPatient) {
      const assistant = assistantsAwaitingPatient[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        awaitingSince: assistant.awaitingPatient ? assistant.awaitingPatient.video : undefined,
      }
      nextAssistants[assistantId] = assistantCard
    }
    for (const assistantId in assistantsInSupport) {
      const assistant = assistantsInSupport[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      let support: Partial<LiveSupport> =
        assistant.runningSupportId && liveSupportsInProgress ? liveSupportsInProgress[assistant.runningSupportId] || {} : {}
      if (assistant.runningSupportId && !support)
        support = liveSupportsPendingStaffReview ? liveSupportsPendingStaffReview[assistant.runningSupportId] || {} : {}
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        queuePriority: support.queuePriority,
        returningSupportId: support.returningSupportId,
        supportId: assistant.runningSupportId,
        supportStartTime: support.startTime,
        supportEndTime: support.endTime,
      }
      nextAssistants[assistantId] = assistantCard
    }
    for (const assistantId in assistantsQuittedRecently) {
      const assistant = assistantsQuittedRecently[assistantId]
      if (mainCorporationId && !get(assistant.corporationIds, mainCorporationId, false)) continue
      activeUsers[assistantId] = true
      const assistantCard: LiveUserKanbanCardProps = {
        userId: assistantId,
        userType: 'assistant',
        onlineDevices: size(assistant.onlineDevices),
        quitSince: assistant.quitTime,
      }
      nextAssistants[assistantId] = assistantCard
    }

    // ======== patients awaiting operator list
    for (const patientId in patientsAwaitingOperator) {
      const patient = patientsAwaitingOperator[patientId]
      if (mainCorporationId && patient.corporationId !== mainCorporationId) continue
      activeUsers[patientId] = true
      const patientCard: LiveUserKanbanCardProps = {
        userId: patientId,
        userType: 'patient',
        onlineDevices: size(patient.onlineDevices),
        awaitingSince: patient.awaitingOperator ? patient.awaitingOperator.video : undefined,
        queuePriority: patient.queuePriority,
        preferredStaffId: patient.preferredStaffId,
        returningSupportId: patient.returningSupportId,
      }
      nextPatientsAwaitingOperator[patientId] = patientCard
    }

    // ======== patients awaiting doctorlist
    for (const patientId in patientsAwaitingDoctor) {
      const patient = patientsAwaitingDoctor[patientId]
      if (mainCorporationId && patient.corporationId !== mainCorporationId) continue
      activeUsers[patientId] = true
      const patientCard: LiveUserKanbanCardProps = {
        userId: patientId,
        userType: 'patient',
        onlineDevices: size(patient.onlineDevices),
        awaitingSince: patient.awaitingDoctor ? patient.awaitingDoctor.video : undefined,
        queuePriority: patient.queuePriority,
        preferredStaffId: patient.preferredStaffId,
        returningSupportId: patient.returningSupportId,
      }
      nextPatientsAwaitingDoctor[patientId] = patientCard
    }

    // ======== patients awaiting assistant list
    for (const patientId in patientsAwaitingAssistant) {
      const patient = patientsAwaitingAssistant[patientId]
      if (mainCorporationId && patient.corporationId !== mainCorporationId) continue
      activeUsers[patientId] = true
      const patientCard: LiveUserKanbanCardProps = {
        userId: patientId,
        userType: 'patient',
        onlineDevices: size(patient.onlineDevices),
        awaitingSince: patient.awaitingAssistant ? patient.awaitingAssistant.video : undefined,
        queuePriority: patient.queuePriority,
        preferredStaffId: patient.preferredStaffId,
        returningSupportId: patient.returningSupportId,
      }
      nextPatientsAwaitingAssistant[patientId] = patientCard
    }

    // ======== sort lists
    const nextKanbanState: LiveSupportKanbanState = {
      patientsAwaitingOperator: [],
      operators: [],
      patientsAwaitingDoctor: [],
      doctors: [],
      patientsAwaitingAssistant: [],
      assistants: [],
      activeUsers: 0,
    }
    nextKanbanState.patientsAwaitingOperator = sortBy(sortBy(nextPatientsAwaitingOperator, 'awaitingSince'), 'queuePriority')
    nextKanbanState.operators = reverse(
      sortBy(nextOperators, (op) => {
        if (op.supportId && op.supportStartTime && !op.supportEndTime) return `${1}_${op.supportStartTime}` // in support
        if (op.supportId && !op.supportStartTime && !op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.supportId && op.supportStartTime && op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.quitSince) return `${4}_${op.quitSince}` // video off
        if (op.awaitingSince) return `${2}_${op.awaitingSince}` // waiting
        return '0_'
      })
    )
    nextKanbanState.patientsAwaitingDoctor = sortBy(sortBy(nextPatientsAwaitingDoctor, 'awaitingSince'), 'queuePriority')
    nextKanbanState.doctors = reverse(
      sortBy(nextDoctors, (d) => {
        if (d.supportId && d.supportStartTime && !d.supportEndTime) return `${1}_${d.supportStartTime}` // in support
        if (d.supportId && !d.supportStartTime && !d.supportEndTime) return `${3}_${d.supportStartTime}` // reviewing
        if (d.supportId && d.supportStartTime && d.supportEndTime) return `${3}_${d.supportStartTime}` // reviewing
        if (d.quitSince) return `${4}_${d.quitSince}` // video off
        if (d.awaitingSince) return `${2}_${d.awaitingSince}` // waiting
        return '0_'
      })
    )
    nextKanbanState.patientsAwaitingAssistant = sortBy(sortBy(nextPatientsAwaitingAssistant, 'awaitingSince'), 'queuePriority')
    nextKanbanState.assistants = reverse(
      sortBy(nextAssistants, (op) => {
        if (op.supportId && op.supportStartTime && !op.supportEndTime) return `${1}_${op.supportStartTime}` // in support
        if (op.supportId && !op.supportStartTime && !op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.supportId && op.supportStartTime && op.supportEndTime) return `${3}_${op.supportStartTime}` // reviewing
        if (op.quitSince) return `${4}_${op.quitSince}` // video off
        if (op.awaitingSince) return `${2}_${op.awaitingSince}` // waiting
        return '0_'
      })
    )
    nextKanbanState.activeUsers = size(activeUsers)
    setKanbanState(nextKanbanState)
  }

  const { run: throttleUpdateKanbanState } = useThrottleFn(updateKanbanState, 200)
  React.useEffect(throttleUpdateKanbanState, [
    patientsAwaitingOperator,
    patientsAwaitingDoctor,
    patientsAwaitingAssistant,
    operatorsAwaitingPatient,
    operatorsInSupport,
    operatorsQuittedRecently,
    doctorsAwaitingPatient,
    doctorsInSupport,
    doctorsQuittedRecently,
    assistantsAwaitingPatient,
    assistantsInSupport,
    assistantsQuittedRecently,
    liveSupportsInProgress,
    liveSupportsPendingStaffReview,
  ])

  const loading =
    patientsAwaitingOperatorLoading ||
    patientsAwaitingDoctorLoading ||
    patientsAwaitingAssistantLoading ||
    operatorsAwaitingPatientLoading ||
    operatorsInSupportLoading ||
    operatorsQuittedRecentlyLoading ||
    doctorsAwaitingPatientLoading ||
    doctorsInSupportLoading ||
    doctorsQuittedRecentlyLoading ||
    assistantsAwaitingPatientLoading ||
    assistantsInSupportLoading ||
    assistantsQuittedRecentlyLoading ||
    liveSupportsInProgressLoading ||
    liveSupportsPendingStaffReviewLoading

  return [kanbanState, loading]
}
