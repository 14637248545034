/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Swal from 'sweetalert2'
import { adminFlag, devFlag, mainCorporationId } from '../clients/iron-fit'

export const PageTitle: React.FC<{
  title: string
  noteStats?: number | string
  noteDescription?: string
  enableCorpsFilter?: boolean
}> = React.memo(({ title, noteStats, noteDescription, enableCorpsFilter }) => {
  const openCorporationFilterSelector = React.useCallback(async () => {
    const { value: filterCorporationId } = await Swal.fire({
      title: 'Filtro por Corporação',
      input: 'select',
      inputOptions: {
        _all: 'Todos',
        cassi: 'Cassi',
        bemcassi: 'Bem Cassi',
        sesisp: 'Sesi SP',
        siriolibanes: 'Sírio Libanês',
        plansaude: 'Plansaúde',
      },
      inputPlaceholder: 'Selecione...',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Filtrar',
    })
    if (filterCorporationId) {
      const url = new URL(window.location.href)
      if (filterCorporationId === '_all') url.searchParams.delete('mainCorporationId')
      else url.searchParams.set('mainCorporationId', filterCorporationId)
      window.location.assign(url.href)
    }
  }, [])
  return (
    <div className="page-title mt-4">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-start mb-3 mb-md-0">
          <div className="d-inline-block">
            <h5 className="h4 d-inline-block font-weight-400 mb-0 text-white">{title}</h5>
          </div>

          <div className="align-items-center ml-4 d-inline-flex">
            <span className="h4 text-info text-uppercase mb-0 mr-2">{noteStats}</span>
            <span className="text-sm opacity-7 text-white">{noteDescription}</span>
          </div>

          {mainCorporationId && <p className="text-sm text-info d-inline-block ml-4 mb-0">{mainCorporationId.toUpperCase()}</p>}
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-between justify-content-md-end">
          {/* <div className="actions actions-dark d-inline-block">
              <a href="#" className="action-item ml-md-4">
                <i className="fas fa-file-export mr-2"></i>Export
              </a>
              <a href="#" className="action-item ml-3">
                <i className="fas fa-cog mr-2"></i>Settings
              </a>
            </div> */}
          {enableCorpsFilter && (adminFlag || devFlag) && (
            <a className="btn btn-sm btn-white btn-icon-only rounded-circle ml-4" onClick={openCorporationFilterSelector}>
              <span className="btn-inner--icon">
                <i className="fas fa-filter"></i>
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
})
