/* eslint-disable @typescript-eslint/no-unused-vars, jsx-a11y/anchor-is-valid, jsx-a11y/img-redundant-alt */
import React from 'react'
import { map, sortBy } from 'lodash'

import { auth, liveSupport, devFlag } from '../../clients/iron-fit'
import './QueueMonitorPage.styles.scss'

import { PageTitle, KanbanWrapper, KanbanCol } from '../../components'
import { LiveUserKanbanCard } from './components/LiveUserKanbanCard'
import { LiveSupportWithSyncPendingItem } from './components/LiveSupportWithSyncPendingItem'
import { useLiveSupportKanbanState } from './live-support-kanban-state.hooks'

export const QueueMonitorPage: React.FC = () => {
  const [, userIdLoading] = auth.useAuthUserId()

  const [kanbanState, kanbanStateLoading] = useLiveSupportKanbanState()

  const [supportsPendingSync, supportsPendingSyncLoading] = liveSupport.useLiveSupportsSync({
    syncPending: devFlag ? true : undefined,
    first: devFlag ? undefined : 1,
  })
  const pendingSync = React.useMemo(() => {
    const supports = sortBy(
      map(supportsPendingSync, (support, supportId) => ({ supportId, ...support })),
      'updateTime'
    )
    return supports
  }, [supportsPendingSync])

  const loading = userIdLoading || kanbanStateLoading || supportsPendingSyncLoading

  return (
    <div className="page-content queue-monitor-page">
      <PageTitle
        title="Iron Live Support Monitor"
        noteStats={loading ? undefined : kanbanState.activeUsers}
        noteDescription={loading ? undefined : 'Users Active'}
        enableCorpsFilter
      />
      <div className="card overflow-hidden">
        <KanbanWrapper>
          <KanbanCol title="Triage Patients" counter={kanbanState.patientsAwaitingOperator.length}>
            {kanbanState.patientsAwaitingOperator.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Operators" counter={kanbanState.operators.length}>
            {kanbanState.operators.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Patients" counter={kanbanState.patientsAwaitingDoctor.length}>
            {kanbanState.patientsAwaitingDoctor.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Doctors" counter={kanbanState.doctors.length}>
            {kanbanState.doctors.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Patients" counter={kanbanState.patientsAwaitingAssistant.length}>
            {kanbanState.patientsAwaitingAssistant.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          <KanbanCol title="Working Assistants" counter={kanbanState.assistants.length}>
            {kanbanState.assistants.map((user) => (
              <LiveUserKanbanCard key={user.userId} {...user} />
            ))}
          </KanbanCol>
          {devFlag && (
            <KanbanCol title="Synchronizing" counter={pendingSync.length}>
              {pendingSync.map((support) => (
                <LiveSupportWithSyncPendingItem key={support.supportId} supportId={support.supportId} support={support} />
              ))}
            </KanbanCol>
          )}
        </KanbanWrapper>
      </div>
    </div>
  )
}
